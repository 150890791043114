<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-12">{{ reseller.name }}</span>
    </div>

    <div class="row">
      <div class="col-9">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
          <dd class="col-sm-8">
            {{ reseller.name }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
          <dd class="col-sm-8">
            <div v-html="reseller.excerpt"></div>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EMAIL") }}</dt>
          <dd class="col-sm-8">
            {{ reseller.email }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("COMMON.PHONE") }}
          </dt>
          <dd class="col-sm-8">
            {{
              reseller.phone_type
                ? $t(`COMMON.PHONE_TYPE_${reseller.phone_type}`)
                : null
            }}:
            {{ reseller.phone }}
            {{
              reseller.phone_extension
                ? ` (ext: ${reseller.phone_extension})`
                : null
            }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("COMMON.OTHER_PHONES") }}
          </dt>
          <dd class="col-sm-8">
            <span v-for="(line, index) in reseller.other_phones" :key="index">
              {{ $t(`COMMON.PHONE_TYPE_${line.type}`) }}:
              {{ line.phoneNumber }}
              {{ line.extension ? ` (ext: ${line.extension})` : null }} <br />
            </span>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
          <dd class="col-sm-8">
            {{ reseller.address }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.OWNER") }}</dt>
          <dd class="col-sm-8">
            <router-link
              :to="{ name: 'View User', params: { id: reseller.owner.id } }"
            >
              {{
                `${reseller.owner.firstname} ${reseller.owner.lastname} - ${reseller.owner.email}`
              }}
            </router-link>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="reseller.created_at">
            {{ $formatDate(reseller.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="reseller.updated_at">
            {{ $formatDate(reseller.updated_at) }}
          </dd>
        </dl>
      </div>
      <div class="col-3">
        <img
          v-if="reseller.logo"
          :src="`${reseller.logo}`"
          class="argon-image"
          style="width: 100%"
        />
        <img v-else src="/img/placeholder.jpg" class="argon-image" />
      </div>
    </div>

    <h2 class="mt-5 mb-3">{{ $t("RESELLERS.CONFIG_MANAGER") }}</h2>

    <div class="row">
      <div class="col-9">
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("RESELLERS.CONFIG_MANAGER_APP_NAME") }}
          </dt>
          <dd class="col-sm-8">
            {{ reseller.config_manager_app_name }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("RESELLERS.CONFIG_MANAGER_URL_REGEX") }}
          </dt>
          <dd class="col-sm-8">
            {{ reseller.config_manager_url_regex }}
          </dd>
        </dl>
      </div>
      <div class="col-3">
        <img
          v-if="reseller.config_manager_app_logo"
          :src="`${reseller.config_manager_app_logo}`"
          class="argon-image"
          style="width: 100%"
        />
        <img v-else src="/img/placeholder.jpg" class="argon-image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reseller-view-global",

  components: {},

  props: ["reseller"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    reseller(reseller) {},
  },
};
</script>
